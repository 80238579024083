import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import Content from '../components/Content';
import Slices from '../slices/Slices';
import HireUsBase from '../components/HireUsBase';

const Wrapper = styled.div`
  h3 {
    font-size: ${props => props.theme.fontSize.heading2};
    line-height: ${props => props.theme.lineHeight.heading2};
    font-weight: 900;
    margin: 0 0 3rem 0;
    color: ${props => props.theme.colors.violet};
  }
  p {
    font-size: ${props => props.theme.fontSize.textBody};
    line-height: ${props => props.theme.lineHeight.textBody};
    font-weight: 400;
    margin: 3rem 0 0 0;
    color: ${props => props.theme.colors.colorTextGrey};
    &:first-child {
      margin: 0;
    }
  }
  img {
    display: block;
  }
  h4 {
    font-size: ${props => props.theme.fontSize.heading3};
    line-height: 1.2;
    font-weight: 900;
  }
`

const Flex = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoint.sl}) {
    flex-direction: column;
  }
`

const Hero = styled.div`
  padding: 4vw 0 6vw 0;
  display: flex;
  flex-direction: column;
`

const FixContent = styled(Content)`
  flex: 1 0;
  align-items: center;
  display: flex;
`

const HeroLeft = styled.div`
  width: 50%;
  @media (max-width: ${props => props.theme.breakpoint.sl}) {
    width: 100%;
  }
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  .flex {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    justify-content: center;
  }
  h5 {
    font-size: ${props => props.theme.fontSize.textSmall};
    line-height: ${props => props.theme.lineHeight.textSmall};
    margin: 0;
    font-weight: 600;
    font-style: italic;
  }
  h2 {
    font-size: ${props => props.theme.fontSize.headingLarge};
    line-height: ${props => props.theme.lineHeight.headingLarge};
    font-weight: 900;
  }
  strong {
    font-size: ${props => props.theme.fontSize.textLead};
    line-height: 1;
    font-weight: 400;
    padding: 2rem 0 2.7rem 0;
    display: inline-block;
    border-bottom: 6px solid ${props => props.theme.colors.lightgreen};
  }
`


const HeroRight = styled.div`
  @media (max-width: ${props => props.theme.breakpoint.sl}) {
    width: 100%;
  }
  justify-content: right;
  .width {
    width: 80%;
    float: right;
    @media (max-width: ${props => props.theme.breakpoint.sl}) {
      margin-top: 4rem;
      width: 100%;
    }
  }
  h5 {
    margin: 0;
    font-size: ${props => props.theme.fontSize.textSmall};
    line-height: ${props => props.theme.lineHeight.textSmall};
    font-weight: 600;
  }
  ul {
    list-style: none;
    margin: 0 0 3rem 0;
    li {
      font-weight: 400;
      display: inline-block;
      font-size: ${props => props.theme.fontSize.textSmall};
      line-height: ${props => props.theme.lineHeight.textSmall};
    }
    &:last-child {
      margin: 0;
    }
  }
  div {
    p {
      font-size: ${props => props.theme.fontSize.textSmall};
      line-height: ${props => props.theme.lineHeight.textSmall};
      margin: 0;
    }
    a {
      color: ${props => props.theme.colors.violet};
    }
  }
`

const ProjectTemplate = ({ data }) => {
  const { title, description, body, results, client, deliverables_type, tech_stack, project_type } = data.project.data
  const { specializations } = data.allPrismicSpecializationLinks.edges[0].node.data
  return (
    <Layout>
      <SEO />
      <Helmet title={title.text} />
      <Wrapper>
        <Header specializations={specializations} menu />
        <Hero>
          <FixContent>
            <Flex>
              <HeroLeft>
                <h5>Case study</h5>
                <div className="flex">
                  <div>
                    <h2>{title.text}</h2>
                    <strong>{description.text}</strong>
                  </div>
                </div>
              </HeroLeft>
              <HeroRight>
                <div className="width">
                  <h5>Expertise:</h5>
                  <ul>
                    {project_type.map(
                      ({ specialization }) => specialization && <li>{specialization.document[0].data.name},&nbsp;</li>
                    )}
                  </ul>
                  <h5>Deliverables</h5>
                  <ul>
                    {deliverables_type.map(
                      ({ deliverable }) => deliverable && <li>{deliverable.document[0].data.name},&nbsp;</li>
                    )}
                  </ul>
                  <h5>Technologies</h5>
                  <ul>
                    {tech_stack.map(({ technology }) => technology && <li>{technology.document[0].data.name},&nbsp;</li>)}
                  </ul>
                  <h5>Results</h5>
                  <div dangerouslySetInnerHTML={{ __html: results.html }} />
                </div>
              </HeroRight>
            </Flex>
          </FixContent>
        </Hero>
        <Slices body={body} />
      </Wrapper>
      <HireUsBase />
      <Footer />
    </Layout>
  );
};

export default ProjectTemplate;

ProjectTemplate.propTypes = {
  data: PropTypes.shape({
    project: PropTypes.object.isRequired,
    allPrismicSpecializationLinks: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query ProjectBySlug($uid: String!) {
    project: prismicProjects(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        client {
          text
        }
        description {
          text
        }
        results {
          html
        }
        tech_stack {
          technology {
            id
            document {
              uid
              data {
                name
              }
            }
          }
        }
        project_type {
          specialization {
            id
            document {
              uid
              data {
                name
              }
            }
          }
        }
        deliverables_type {
          deliverable {
            id
            document {
              uid
              data {
                name
              }
            }
          }
        }
        body {
          ... on PrismicProjectsBodyBigImg {
            id
            slice_type
            primary {
              img {
                url
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                    sizes(maxWidth: 700, quality: 90) {
                      ...GatsbyImageSharpSizes_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProjectsBodyBigImgNoBottomPadding {
            id
            slice_type
            primary {
              img {
                url
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                    sizes(maxWidth: 700, quality: 90) {
                      ...GatsbyImageSharpSizes_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProjectsBodyGrid {
            id
            slice_type
            items {
              item {
                html
              }
            }
          }
          ... on PrismicProjectsBodyText {
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicProjectsBodyTextLeftImgRight {
            id
            slice_type
            primary {
              img {
                url
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                    sizes(maxWidth: 700, quality: 90) {
                      ...GatsbyImageSharpSizes_withWebp_noBase64
                    }
                  }
                }
              }
              content {
                html
              }
            }
          }
          ... on PrismicProjectsBodyTextRightImgLeft {
            id
            slice_type
            primary {
              content {
                html
              }
              img {
                url
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                    sizes(maxWidth: 700, quality: 90) {
                      ...GatsbyImageSharpSizes_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicSpecializationLinks {
      edges {
        node {
          data {
            specializations {
              specialization {
                document {
                  data {
                    name
                  }
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`;
